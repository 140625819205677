import React,{useState} from "react"
import Layout from "../components/Layout"
import { Container } from "react-bootstrap"
import { useEffect } from 'react'
import '../styles/about.css'
import AOS from 'aos';
import Head from '../components/Head';


function About() {
  const [wind, setWind] = useState();

  useEffect(() => {
    AOS.init({
      duration : 2000
    });
    AOS.refresh();


    setWind(window.innerWidth)

  }, []);

  return (

    <div className="overflow-hidden">
      <Head title="About"/>
      <Layout>
        <Container className="about px-5">

          {wind > 567 ? 

            <section >
              <header className="sectionHeader halfBorder  my-5  " as="h1">About KSSS</header>

              <div className='row aboutSection '>
                  <div className='col-lg-7 leftPart' data-aos="fade-down-left">
                      <p className='sectionContent mb-5 '>
                      We believe that every child is uniquely created with divine energy
                      which requires a suitable medium of expression. It is our duty to
                      channelize this hidden energy to the world at large through right
                      education, right values and right action. KSSS PU College imbibes
                      these ideals for purposeful education across caste, creed and
                      nationality in the true spirit of Sri Swamiji's vision.
                      <br />
                      <br />
                      Shri Pejawar Swamiji is one of the living saints who are accepted
                      as moral and spiritual leader by all section of the society in our
                      country. A lucid orator, a keen debater, a rapt teacher, a
                      profound scholar, an enquiring critic, a farsighted educational
                      aboutanizer, a compassionate spiritual preceptor.
                      <br />
                      <br />
                      Swamiji has been working tirelessly in the field of education for
                      many decades and it is under his guidance that institutes like the
                      Janata Shikshan Samiti(JSS)Dharwad and Poorna Pragnya Vidyapeeth,
                      Bangalore have gained the reputation of being the center of
                      excellence providing quality education. It was also a dream of
                      Swamiji to provide education to the needy students of the rural
                      area, and this dream has been realized by starting this college by
                      name as KSSS(Karnataka Shikshana Seva Samithi at Budersingi
                      Village). Our vast 10 acre is surrounded by lush greenery and
                      provided a serene and pleasant ambiance that will be conductive to
                      learning. The main college building is located atop a hill and has
                      spacious classrooms which are airy and well lit. It has well
                      equipped laboratories, reading rooms and an extensive library and
                      two play grounds for sports activities.
                      <br />
                      <br />
                      Our campus is located on the outskirts of Hubli, near Kundagol
                      Cross just off National Highway(NH-4) and is a distance of 7 Km.
                      from Old Bus stand, Hubli. For the convenience of students we have
                      arranged for Transport facilities from Hubli at regular intervals.
                      </p>
                    
                  </div>

                  <div className='col-lg-5 rightPart' data-aos="fade-down-right" data-aos-delay='1000'>
                        <div className="aboutImageContainer">
                          <div className="aboutImage"><h1 title="about ksss college" className="d-none">about ksss college</h1></div>
                        </div>
                  </div>
              </div>
            </section>
          :
            <section >
              <header className="sectionHeader halfBorder  my-5  " as="h1">The about</header>

              <div className='aboutSection1 '>
                <div className='' data-aos="fade-left">
                    <p className='sectionContent mb-5 '>
                      We believe that every child is uniquely created with divine energy
                      which requires a suitable medium of expression. It is our duty to
                      channelize this hidden energy to the world at large through right
                      education, right values and right action. KSSS PU College imbibes
                      these ideals for purposeful education across caste, creed and
                      nationality in the true spirit of Sri Swamiji's vision.
                      <br />
                      <br />
                      Shri Pejawar Swamiji is one of the living saints who are accepted
                      as moral and spiritual leader by all section of the society in our
                      country. A lucid orator, a keen debater, a rapt teacher, a
                      profound scholar, an enquiring critic, a farsighted educational
                      aboutanizer, a compassionate spiritual preceptor.
                    </p>
                  
                </div>
                <div className='rightPart' data-aos="zoom-in" >
                      <div className="aboutImageContainer">
                        <div className="aboutImage"><h1 title="about ksss college" className="d-none">about ksss college</h1></div>
                      </div>
                </div>
                <div data-aos="fade-right">
                  <p className='sectionContent mb-5 mt-5'>
                    Swamiji has been working tirelessly in the field of education for
                    many decades and it is under his guidance that institutes like the
                    Janata Shikshan Samiti(JSS)Dharwad and Poorna Pragnya Vidyapeeth,
                    Bangalore have gained the reputation of being the center of
                    excellence providing quality education. It was also a dream of
                    Swamiji to provide education to the needy students of the rural
                    area, and this dream has been realized by starting this college by
                    name as KSSS(Karnataka Shikshana Seva Samithi at Budersingi
                    Village). Our vast 10 acre is surrounded by lush greenery and
                    provided a serene and pleasant ambiance that will be conductive to
                    learning. The main college building is located atop a hill and has
                    spacious classrooms which are airy and well lit. It has well
                    equipped laboratories, reading rooms and an extensive library and
                    two play grounds for sports activities.
                    <br />
                    <br />
                    Our campus is located on the outskirts of Hubli, near Kundagol
                    Cross just off National Highway(NH-4) and is a distance of 7 Km.
                    from Old Bus stand, Hubli. For the convenience of students we have
                    arranged for Transport facilities from Hubli at regular intervals.
                  </p>
                </div>
              </div>
            </section>
          } 
          
        </Container>

      </Layout>
    </div>
  )
}

export default About
